import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
    Phone
  } from "./FooterStyles";


export default function App() {
  return (
    <Box>
    <MDBFooter bgColor='blue' className='text-center text-lg-start text-white'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h5 className='text-uppercase fw-bold mb-4'>
               Sundar Printing
              </h5>
              <p className='text-white'>
                 New Age Printing Solution Provider
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Our Policy</h6>
              <p>
                <a href="https://printsundar.com/#/contact" className='text-reset'>
                Contact Us
                </a>
              </p>
              <p>
                <a href="https://printsundar.com/#/TermsAndCondition" className='text-reset'>
                Terms and Conditions
                </a>
              </p>
              <p>
                <a href="https://printsundar.com/#/PrivacyPolicy" className='text-reset'>
                Privacy and Cookie Policy
                </a>
              </p>
              <p>
                <a href="https://printsundar.com/#/RefundPolicy" className='text-reset'>
                Refund Policy
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Our Policies Cont..</h6>
              <p>
                <a href="https://printsundar.com/#/ShippingPolicy" className='text-reset'>
                Shipping policy
                </a>
              </p>
              <p>
                <a href="https://printsundar.com/#/CancellationPolicy" className='text-reset'>
                Cancellation Policy
                </a>
              </p>
             
              
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Kolkata, WB 712101, India
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-1" />
                contactus@printsundar.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 7003895249
              </p>
             </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='https://www.printsundar.com/'>
          printsundar.com
        </a>
      </div>
    </MDBFooter>
    </Box>
  );
}