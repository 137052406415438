import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import Image from "react-bootstrap/Image";

import logo from '../images/logo.png';

const Navbar = () => {
const { loading, loginWithRedirect, logout,isAuthenticated, user } = useAuth0();
const state = useSelector((state) => state.handleCart);
const[loggedUserName, setloggedUserName] = useState('');
const[flag, setFlag] = useState(false);


useEffect(() => {
   
    if (isAuthenticated) {
        localStorage.setItem("UserInfo", JSON.stringify({user}));
        setloggedUserName(user.name);
    }
    else{
       localStorage.removeItem("UserInfo");

    }
    
  }, [isAuthenticated]);
  
   return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light bg-primary font-color-white py-3 sticky-top">
            <div className="container">
               <button 
                    type="button" 
                    data-bs-toggle="collapse" 
                    className="navbar-toggler" 
                    data-bs-target="#navbarSupportedContent" 
                    aria-controls="navbarSupportedContent" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation">
                    <i class="fas fa-bars text-white"></i>
                </button>

            <Image src={logo}  rounded /> 
                <NavLink className="navbar-brand fw-bold text-white fs-4 px-2" to="/"> Sundar Printing</NavLink>
               
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto my-2 text-center">
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/">Home </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/product">Products</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/about">About Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/contact">Contact Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/BusinessSupport">Business Support</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/Vacancy">Notice</NavLink>
                        </li>
                    </ul>
                     <div>
                     { isAuthenticated &&
              
                            <h4 className="display-12 text-right text-white">Welcome {loggedUserName}</h4>

                    }
                     </div>
                     <div className="buttons text-center text-white">
                        { isAuthenticated?
                           
                        (   <button className="btn btn-outline-dark text-white m-2" onClick={()=>logout()({returnTo:window.location.origin})}><i className="fa fa-sign-in-alt mr-1"></i> LogOut</button>
                         
                           
                        ):
                        (
                            
                            <button className="btn btn-outline-dark text-white m-2" onClick={()=>loginWithRedirect()}><i className="fa fa-sign-in-alt mr-1"></i> LogIn</button>
                                                     
                        )

                        }
                                            
                        <NavLink to="/cart" className="btn btn-outline-dark text-white m-2"><i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length}) </NavLink>
                    </div>        
                </div>
            </div>
        </nav>
        </>
    )
  
}

export default Navbar